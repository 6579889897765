<template>
  <component :is="countryData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="countryData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching country data
      </h4>
      <div class="alert-body">
        No country found with this country id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'countries'}"
        >
          Countries List
        </b-link>
        for other countries.
      </div>
    </b-alert>
    <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col md="6">
          <b-form-group
              label="Title En"
              label-for="mc-title-en"
          >
            <b-form-input
                v-model="countryData.title_en"
                id="mc-title-en"
                placeholder="Title En"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title Ar"
              label-for="mc-title-ar"
          >
            <b-form-input
                v-model="countryData.title_ar"
                id="mc-title-ar"
                placeholder="Title Ar"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
              label="Country Code"
              label-for="mc-country-code"
          >
            <b-form-input
                v-model="countryData.country_code"
                id="mc-country-code"
                placeholder="Country Code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
              label="Is Active"
              label-for="is_active"
          >
            <b-form-checkbox
                id="is_active"
                v-model="countryData.is_active"
                :checked=countryData.is_active
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <!-- Flag -->
          <file
              title="Select Country Flag"
              :default_place_holder_src="countryData.flag"
              @file_uploaded="({media})=>{countryData.flag= media.url; countryFlagId = media.id}"

          ></file>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import countryStoreModule from '../countryStoreModule'
import {BAlert, BButton, BCard, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BLink, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton
  },
  directives: {
    Ripple,
  },
  setup() {
    const blankCountryData = {
      title_en: '',
      title_ar: '',
      is_active: true,
      country_code: '',
      flag: null,
    }
    const countryFlagId = ref(null);
    const toast = useToast()

    const countryData = ref(JSON.parse(JSON.stringify(blankCountryData)))

    const COUNTRY_APP_STORE_MODULE_NAME = 'app-country'

    // Register module
    if (!store.hasModule(COUNTRY_APP_STORE_MODULE_NAME)) store.registerModule(COUNTRY_APP_STORE_MODULE_NAME, countryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COUNTRY_APP_STORE_MODULE_NAME)) store.unregisterModule(COUNTRY_APP_STORE_MODULE_NAME)
    })
    store.dispatch('app-country/fetchCountry', {id: router.currentRoute.params.id})
        .then(response => {
          countryData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            countryData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        title_en: countryData.value.title_en,
        title_ar: countryData.value.title_ar,
        is_active: countryData.value.is_active,
        country_code: countryData.value.country_code,
      }
      if (countryFlagId.value != null) {
        data.flag = countryFlagId.value
      }
      store.dispatch('app-country/updateCountry', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'countries'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Country Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    return {
      onUpdate,
      countryData,
      countryFlagId
    }
  },
}
</script>

<style>

</style>
